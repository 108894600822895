.loan{
    background-color: $color-green-background;
    min-height: 100vh;
    padding: 40px 20px;

    &__logo{
        height: 57px;
        object-fit: contain;
        display: block;
        margin: 0 auto;
    }

    h1{
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin-top: 14px;
        color: $green;
    }

    &__container{
        max-width: 400px;
        margin: 0 auto;

        width: 100%;
        margin: 0 auto;
        margin-top: 39px;
        border-radius: 12px;
        border: 1px solid $green;
        background: $white;
        box-shadow: -4px 4px 0px 0px $green;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 25px;

        &__content{
            display: flex;
            align-items: center;
            width: 100%;
            &:not(:first-child){
                margin-top: 17px;
            }

            img{
                height: 22px;
                object-fit: contain;
            }

            p{
                font-size: 16px;
                display: flex;
                align-items: center;
                column-gap: 2px;

                &:first-child{
                    width: 45%;
                }
                
            }
        }

        h2{
            color: $green;
            font-weight: 400;
            font-size: 25px;
            text-align: center;
        }

        img{
            width: 80%;
        }

        &__btns{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 20px;
            width: 100%;
            margin-top: 30px;

            a{
                width: 80%;
                text-align: center;
                text-decoration: none;
            }
        }
    }
}

.profile{
    min-height: 95vh;
    padding: 40px 20px;

    h1{
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        color: $green;
    }

    &__container{
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
        margin: 0 auto;
        margin-top: 39px;
        border-radius: 12px;
        border: 1px solid $green;
        background: $white;
        box-shadow: -4px 4px 0px 0px $green;
        padding: 45px 27px;

        &__content{
            display: flex;
            align-items: center;
            &:not(:first-child){
                margin-top: 17px;
            }

            p{
                font-size: 16px;

                &:first-child{
                    width: 45%;
                }
                
            }
        }

        &__btn{
            margin-top: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            a{
                text-decoration: none;
            }
        }
    }
}

.invoices{
    min-height: 95vh;
    padding: 40px 12.5px;

    h1{
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        color: $green;
    }

    &__container{
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 12px;
        border: 1px solid $green;
        background: $white;
        box-shadow: -4px 4px 0px 0px $green;
        padding: 45px 5px;

        &__divider{
            margin-top: 40px;
        }

        h5{
            font-size: 15px;
            margin-left: 8px;
        }

        &__back{
            max-width: 400px;
            margin: 0 auto;
            text-decoration: none;
            font-size: 16px;
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: #000;

            img{
                height: 12px;
                object-fit: contain;
            }
        }


        &__btn{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

            a{
                text-decoration: none;
            }
        }

        &__tbtn{
            background-color: $yellow;
            padding: 7px;
            border-radius: 5px;
            color: $white;
            text-decoration: none;
            font-weight: 400;
            font-family: $gilroy;
            box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.61);
        }

        table{
            width: 100%;
            tr{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 15px 0;

                &:not(:last-child){
                    box-shadow: 0px 1px 1px 0px rgba(2, 81, 150, 0.25);
                }

                th{
                    font-size: 19px;
                    font-weight: 600;
                    color: $green;
                }
                
                th, td{
                    width: 24%;

                    &:last-child{
                        width: 40%;
                        a{
                            font-size: 12px;
                        }
                    }
                    
                    font-size: 14px;
                    padding: 6px 0;
                    text-align: center;
                    
                }

                a{
                    text-decoration: none;
                }

                
            }
        }
    }
}

.invoice{
    min-height: 95vh;
    padding: 40px 20px;

    &__container{
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
        margin: 0 auto;
        border-radius: 12px;
        background: $white;

        &__back{
            text-decoration: none;
            font-size: 16px;
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: #000;

            img{
                height: 16px;
                object-fit: contain;
            }
        }

        &__file{
            border: 1px solid $green;
            border-radius: 12px;
            overflow: hidden !important;
            padding: 10px;
            padding-bottom: 20px;
            margin-top: 22px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .page-controls{
                margin-top: -7%;
                background: white;
                transition: opacity ease-in-out .2s;
                box-shadow: 0 10px 10px 0 rgba(16, 36, 94, .1);
                border-radius: 4px;
                z-index: 2;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: center;

                button{
                    width: 44px;
                    height: 44px;
                    background: white;
                    border: 0;
                    font-size: 22px;
                    border-radius: 4px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .PDFDocument {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            
            // .PDFPage {
            //     box-shadow: 0 0 8px rgba(0, 0, 0, .5);
            // }
            
            .PDFPageOne {
                // margin-bottom: 25px;
            }
            
            .PDFPage > canvas {
                max-width: 100%;
                height: auto !important;
            }
        }

        h5{
            margin-top: 40px;
            text-align: center;
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 18px;

            img{
                height: 22px;
                vertical-align: middle;
            }
        }

        &__btn{
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            a{
                text-decoration: none;
                width: 80%;
                text-align: center;
            }
        }
    }
}

.success{
    min-height: 95vh;
    padding: 40px 20px;
    
    h1{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: $green;
        text-transform: uppercase;
    }
    
    &__container{
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
        margin: 0 auto;
        margin-top: 39px;
        border-radius: 16px;
        background: #FAFAFA;
        box-shadow: 1px 1px 20px 0px rgba(0, 168, 151, 0.40), -1px -1px 20px 0px rgba(0, 168, 151, 0.40);
        padding: 17px 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img{
            width: 114px;
            height: 114px;
            object-fit: contain;
        }

        p{
            text-align: center;
            margin: 20px 0;
            font-size: 18px;

            img{
                height: 22px;
                object-fit: contain;
                width: unset;
                vertical-align: middle;
            }

        }

        .bold{
            font-weight: 600;
        }

        &__btn{
            margin-top: 25px;
            margin-bottom: 48px;
            
            a{
                text-decoration: none;
            }
        }
    }
}

.features{
    max-width: 400px;
    margin: 0 auto;

    &__card{
        width: 95%;
        height: 188px;
        background-image: url('../assets/card.png');
        background-size: 100% 100%;
        margin: 0 auto;
        margin-top: 22px;
        color: $color-white-blue;
        position: relative;

        &__txt{
            position: absolute;
            bottom: 5%;
            left: 21px;

            h5{
                font-size: 12.11px;
                font-weight: 400;
            }

            h6{
                color: $green;
                font-size: 32.293px;
                font-weight: 500;
                margin-top: 12px;
            }
        }

    }

    &__points{
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
        padding: 22px 24px;
        border-radius: 12px;
        background: linear-gradient(180deg, rgba(0, 168, 151, 0.25) 0%, rgba(0, 168, 151, 0.13) 41.93%, rgba(0, 168, 151, 0.10) 100%);
        
        &__card{
            display: flex;
            align-items: center;
            border-radius: 12px;
            background: rgba(255, 255, 255, 0.80);
            padding: 12px;
            margin-top: 17px;

            p{
                color: #024351;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                margin-left: 8px;
            }

            img{
                width: 32px;
                height: 32px;
                object-fit: contain;
            }
        }
        a{
            text-decoration: none;
            margin: 0 auto;
            margin-top: 39px;
            display: block;
            text-align: center;
        }
    }
}

.thankyou{
    max-width: 400px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img{
        height: 340px;
        object-fit: contain;
    }

    h2{
        color: #00A897;
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        text-transform: capitalize;
        margin-top: 44px;
    }

    p{
        width: 80%;
        color: #809FBC;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        margin-top: 16px;
    }
}

.mandate{
    min-height: 95vh;
    padding: 40px 20px;

    &__container{
        max-width: 400px;
        margin: 0 auto;

        &__back{
            text-decoration: none;
            font-size: 16px;
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: #000;

            img{
                height: 16px;
                object-fit: contain;
            }
        }

        h4{
            margin-top: 40px;
            text-align: center;
            font-size: 25px;
            font-weight: 400;
            margin-bottom: 57px;
        }

        &__card{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 35px;

            p{
                color: #929DA9;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 3px;
            }

            button{
                width: 100%;
                padding: 27px 0;
                border: none;
                outline: none;
                border-radius: 10.361px;
                border: 0.863px solid #BBB;
                background: #FFF;
                box-shadow: -3.454px 3.454px 0px 0px #BBB;

                color: #00A897;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
            }

            &__active{
                border: 0.863px solid #00A897 !important;
                box-shadow: -3.454px 3.454px 0px 0px #00A897 !important;
            }

            &__error{
                color: #6E6E6E !important;
                p{
                    color: #00A897;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        &__btn{
            display: block;
            margin: 0 auto;
            text-decoration: none;
        }
    }
}

.paylater{
    min-height: 95vh;
    padding: 40px 20px;
    max-width: 400px;
    margin: 0 auto;

    &__container{
        width: 100%;
        margin: 0 auto;
        margin-top: 39px;
        border-radius: 12px;
        border: 1px solid $green;
        background: $white;
        box-shadow: -4px 4px 0px 0px $green;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 25px;

        &__back{
            text-decoration: none;
            font-size: 16px;
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: #000;

            img{
                height: 16px;
                object-fit: contain;
            }
        }

        &__content{
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 10px;
            
            &:not(:first-child){
                margin-top: 17px;
            }

            img{
                height: 22px;
                object-fit: contain;
            }

            p{
                font-size: 16px;
                display: flex;
                align-items: center;
                column-gap: 2px;
                
                &:first-child{
                    width: 50%;
                }
                
                &:not(:first-child) {
                    font-weight: 600;
                }
            }
        }

        &__btn{
            margin-top: 40px;
            text-decoration: none;
        }

    }
}