@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.ttf');
    font-weight: 500;
    font-style: normal;
}

// fonts
$poppins: 'Poppins';
$gilroy: 'Gilroy';

// colors
$green: #00A897;
$green-light--bg: #F0F5F5;
$yellow: #FFA800;
$white: #FFFFFF;
$text-grey: #929DA9;

$color-green-background: #F0F5F5;
$color-white-blue: #D8E6FF;


/*
font-family: 'Gilroy-Bold', sans-serif;
font-family: 'Gilroy-Heavy', sans-serif;
font-family: 'Gilroy-Light', sans-serif;
font-family: 'Gilroy-Medium', sans-serif;
font-family: 'Gilroy-Regular', sans-serif;                                           
*/