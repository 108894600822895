@mixin  laptopL {
    @media only screen and (max-width: 1440px) { 
        @content
    };
}

@mixin laptopT {
    @media only screen and (max-width: 1377px) { 
        @content
    };
}

@mixin  laptop {
    @media only screen and (max-width: 1026px) { 
        @content
    };
}

@mixin  tablet {
    @media only screen and (max-width: 770px) { 
        @content
    };
}

@mixin mobileL{
    @media only screen and (max-width: 450px){
        @content
    };
}

@mixin mobileM {
    @media only screen and (max-width: 376px) { 
        @content
    };
}

@mixin  mobileS {
    @media only screen and (max-width: 320px) { 
        @content
    };
}
