.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    width: 100%;
    z-index: 10;
    position: sticky;
    top: 0;
    background-color: $green-light--bg;

    @include tablet{
        padding: 20px;
    }

    &__logo{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 10px;
        align-items: center;
        color: #484848;

        @include tablet{
            column-gap: 6px;
        }

        p{
            font-size: 20px;

            @include tablet{
                font-size: 16px;
            }
        }

        &__main{
            height: 40px;
            object-fit: contain;
            padding-right: 8px;
            border-right: 2px solid #666666;

            @include tablet{
                height: 30px;
                padding-right: 3px;
            }
        }
    }

    &__logout{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 10px;
        text-decoration: none;
        color: #484848;

        @include tablet{
            column-gap: 6px;
        }

        p{
            font-size: 20px;

            @include tablet{
                display: none;
            }
        }

        img{
            height: 40px;

            @include tablet{
                height: 30px;
            }
        }
    }

    &__menu{
        position: relative; 
        
        img{
            height: 18px;
            margin-right: 5px;
            cursor: pointer;
        }
        
        &__modal{
            display: block;
            top: 70px;

            &__content{
                background: #E2F1F1;
                border: 1px solid $green;
                border-radius: 0;
                box-shadow: none;
                padding: 20px 14px;
                width: 60%;
                margin-left: 0;
                display: flex;
                flex-direction: column;


                a{
                    background-color: #FFFFFF;
                    font-size: 16px;
                    padding: 10px;
                    text-align: center;
                    text-decoration: none;
                    margin-bottom: 20px;
                    border-radius: 10px;
                    border: 1px solid #00A897;

                    &:last-child{
                        margin: 0;
                    }
                }
                
            }
        }
        
    }

}