.welcome{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    @include tablet{
        // min-height: 90vh;
    }

    
    img{
        height: 80px;
        object-fit: contain;
        margin-bottom: 15px;

        @include tablet{
            height: 55px;
        }
    }

    h1{
        font-size: 48px;
        color: $green;
        font-weight: 400;

        @include tablet{
            font-size: 20px;
        }
    }

    h2{
        font-size: 40px;
        font-weight: 500;
        color: #5E5E5E;
        margin: 30px 0;

        @include tablet{
            font-size: 20px;
            text-align: center;
            margin: 30px 0;
        }
    }

    div{
        // display: flex;
        color: #5E5E5E;
        font-size: 24px;
        text-align: center;
        margin-bottom: 15px;

        @include tablet{
            margin-bottom: 6px;
        }

        b{
            font-size: 24px;

            @include tablet{
                font-size: 16px;
            }

            @include mobileS{
                font-size: 13px;
            }
        }

        p{
            font-size: 24px;
            margin: 0 auto;

            @include tablet{
                font-size: 16px;
            }

            @include mobileS{
                font-size: 13px;
            }
        }

    }

    select{
        background: #FFFFFF;
        border: 1px solid #00A897;
        box-shadow: -4px 4px 0px #00A897;
        border-radius: 10px;
        padding: 15px 20px;
        font-size: 20px;
        outline: none;
        margin: 0 0 30px;
        min-width: 256px;

        option{
            outline: none;
            &:active{

            }
        }
    }

    button{
        cursor: pointer;
    }

    &__form{
        margin-bottom: 20px;

        h3{
            font-size: 19px;
            margin-top: 40px;
        }

        &__number{
            position: relative;
            border: 0.918px solid #00A897;
            background: #FFF;
            box-shadow: -3.6702511310577393px 3.6702511310577393px 0px 0px #00A897;
            min-width: 256px;
            border-radius: 9.176px;
            overflow: hidden;

            span{
                border-radius: 7.341px 0px 0px 7.341px;
                position: absolute;
                left: .8%;
                height: 99%;
                background-color: $green;
                color: $white;
                font-size: 18px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                justify-content: center;
            }

            input{
                padding: 17px;
                border: none;
                border-radius: 9.176px;
                font-size: 18px;
                margin-left: 40px;

                &::placeholder{
                    color: $text-grey;
                }
            }
        }
    }
}

#google_translate_element div{
    font-size: 9px;
};

#google_translate_element div img{
    vertical-align: middle;
    margin-bottom: 0;
    padding:0 5px;
}