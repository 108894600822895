.container{
    max-width: 1600px;
    margin: 0 auto;
    background-color: $green-light--bg;
    font-family: $gilroy;
    font-size: 16px;
}

.contents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.btn{
    width: fit-content;
    padding: 10px 35px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    color: black;

    &__yellow{
        background-color: $yellow;
        color: $white;
        box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.61);
    }
    &__green{
        background-color: $green;
        color: $white;
    }
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center; 
    position: fixed; 
    z-index: 5; 
    left: 0;
    top: 0;
    margin: auto;
    width: 100%; 
    height: 100%; 
    overflow: none; 
    background-color: rgba(0,0,0,0.2); 

    &-content{
        background-color: #FAFAFA ;
        border-radius: 16px;
        margin: auto;
        padding: 30px;
        width: 30%;
        position: relative;
        box-sizing: border-box;
        box-shadow: -1px -1px 20px rgba(0, 168, 151, 0.4), 1px 1px 20px rgba(0, 168, 151, 0.4);

        @include laptop{
            width: 40%;
        }

        @include tablet{
            width: 80%;
        }

        h3{
            font-weight: 500;
            font-size: 25px;
            line-height: 22px;
            color: $green;
            text-align: center;
            margin-bottom: 23px;
            margin-top: 18px;

            @include tablet{
                font-size: 16px;
                font-weight: 600;
                margin-top: 14px;
                margin-bottom: 14px;
            }
           
        }

        img{
            width: 18px;
            height: 18px;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 15px;

            @include tablet{
                width: 15px;
                height: 15px;
            }
        }

        &-details{
            display: flex;
            flex-direction: column;

            &-field{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                column-gap: 15px;
                width: 100%;
                font-size: 18px;
                margin-bottom: 10px;

                h6{
                    display: flex;
                    justify-content: space-between;
                    width: 48%;
                    font-size: 18px;

                    @include tablet{
                        font-size: 14px;
                    }

                    b{
                        font-size: 18px;
                        width: fit-content;

                        @include tablet{
                            font-size: 14px;
                        }
                    }

                }

                p{
                    width: 48%;
                    font-size: 18px;

                    @include tablet{
                        font-size: 14px;
                    }
                }
            }
            
            &-status{
                width: 100%;
                font-size: 18px;
                margin-bottom: 10px;
                border: 2px solid #00A897;
                border-radius: 5px;
                padding: 8px 12px;

                @include tablet{
                    font-size: 16px;
                }

                p{
                    font-size: 18px;
                    color: #00A897;

                    @include tablet{
                        font-size: 14px;
                    }

                } 
            }
        }
    }
}

input[type="number"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  .Toastify__toast{
    z-index: 10000;
  }