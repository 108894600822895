.view{
    width: 100%;
    padding: 40px 50px 100px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: 50px;
    height: 100%;
    min-height: calc(100vh - 90px);
    position: relative;

    @include laptop{
        column-gap: 30px;
        padding: 30px 40px;
    }

    @include tablet{
        padding: 30px 20px;
    }

    &__sidebar{
        background: #E2F1F1;
        border: 1px solid $green;
        box-shadow: -4px 4px 0px #01A897;
        border-radius: 20px;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        width: 215px;

        @include laptop{
            width: 20%;
            padding: 15px;
            row-gap: 15px;
        }

        @include tablet{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            border-radius: 0;
            box-shadow: none;
            padding: 20px 14px;
            height: 100vh;
            display: none;
        }

        a{
            text-decoration: none;
            text-align: center;
            border-radius: 10px;
            color: #000000;
            font-size: 18px;
            
            @include laptop{
                padding: 8px 15px;
                font-size: 14px;
            }
        }
        
        &--active{
            padding: 12px 20px;
            border: 1px solid $green;
            background-color: $white;
        }
    }

    &__card{
        background: #FFFFFF;
        border: 1px solid $green;
        box-shadow: -8px 8px 0px $green;
        border-radius: 20px;
        width: calc(100% - 215px - 50px);
        padding: 30px;
        display: flex;
        background-color: #FAFAFA;

        @include laptop{
            width: 80%;
            padding: 20px 30px;
        }

        @include tablet{
            width: 100%;
            padding: 18px 10px;
            box-shadow: -4px 4px 0px #00A897;
        }

        table{
            width: 100%;
            border-collapse:separate; 
            border-spacing: 0 17px;

            thead{
                background-color: $white;

                th{
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid #E7E7E7;
                    border-top: 1px solid #E7E7E7;
                    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
                    font-weight: 500;
                    font-size: 20px;
                    color: $green;

                    @include tablet{
                        font-size: 16px;
                    }

                    &:first-child{
                        padding-left: 25px;
                        border-left: 1px solid #E7E7E7;
                        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;

                        @include tablet{
                            padding-left: 12px;
                        }
                    }

                    &:last-child{
                        padding-right: 25px;
                        border-right: 1px solid #E7E7E7;
                        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;

                        @include tablet{
                            padding-right: 12px;
                        }
                    }
                }
            }

            tbody{
                
                tr{
                    background-color: $white;
                    box-shadow: 0px 1px 1px rgba(2, 81, 150, 0.25);
                    
                
                    td{
                        padding: 8px 0;
                        text-align: center;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                        color: #000000;

                        @include laptop{
                            font-size: 16px;
                        }

                        @include tablet{
                            font-size: 14px;
                            line-height: 16px;
                        }

                        &:last-child{
                            text-decoration: underline;
                            cursor: pointer;
                            color: #2533AE;
                            font-size: 12px;
                        }
                    }

                }
            }
        }

    }
}