.apply{
    width: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    padding: 20px 60px;

    @include tablet{
        min-height: 100vh;
        padding: 20px;
    }

    &__page{
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;

        @include tablet{
            width: 100%;
        }

        &__index{
            height: 60px;
            width: 60px;
            background: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include tablet{
                width: 40px;
                height: 40px;
            }

            &--active{
                background-color: $green;
            }
            img{
                height: 40px;
                object-fit: contain;

                @include tablet{
                    height: 30px;
                }
            }
        }

        &__sep{
            width: 150px;
            height: 2px;
            background-color: #BFBFBF;

            @include tablet{
                width: 20%;
            }
        }
    }

    &__card{
        background: #FFFFFF;
        border: 1px solid $green;
        box-shadow: -8px 8px 0px $green;
        border-radius: 20px;
        width: 65%;
        padding: 40px 30px;
        display: flex;

        @include tablet{
            width: 100%;
            padding: 15px;
            box-shadow: -4px 4px 0 $green;
            margin-bottom: 70px;
        }

        &__form{
            display: flex;
            flex-direction: column;
            width: 100%;

            &__container{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                column-gap: 8%;
                width: 100%;
                margin: 0 auto;

                &__field{
                    display: flex;
                    flex-direction: column;
                    width: 45%;
                    margin-bottom: 16px;

                    @include tablet{
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    
                    label{
                        font-size: 16px;
                        color: $green;
                        margin-bottom: 10px;

                        @include tablet{
                            margin-bottom: 4px;
                        }
                    }
    
                    input{
                        padding: 12px 15px;
                        border-radius: 5px;
                        border: 1px solid rgba(0, 168, 151, 0.6);
                        font-size: 16px;
                        width: 100%;
                        font-weight: 600;
                        
                        &::placeholder{
                            font-weight: 400;
                            color: #929DA9;
                        }

                        @include tablet{
                            border: 1px solid #818181;
                            padding: 10px 15px;
                        }
                    }

                    button{
                        margin-top: 5px !important;
                        margin-right: auto !important;
                        margin-left: 0 !important;
                        padding: 8px 12px;
                        background-color: #00A897;
                        color: #fff;
                        font-size: 14px;
                        border: none;
                    }

                    select{
                        padding: 12px 15px;
                        border-radius: 5px;
                        border: 1px solid rgba(0, 168, 151, 0.6);
                        color: #929DA9;
                        font-size: 16px;

                    }

                    &__file{
                        input[type="file"]{
                            padding: 6px;
                            border: 2px dotted rgba(0, 168, 151, 0.6);
                            -webkit-appearance: none;
                            text-align: left;
                            -webkit-rtl-ordering:  left;
                            color: $green;
                        }

                        input[type="file"]::-webkit-file-upload-button{
                            -webkit-appearance: none;
                            float: right;
                            margin: 0 0 0 10px;
                            border: 1px solid #aaaaaa;
                            border-radius: 4px;
                            background-color: $green;
                            color: $white;
                            font-size: 14px;
                            padding: 6px 10px;
                         }
                         
                    }

                    &--guide{
                        input{
                            padding: 12px 15px;
                            border-radius: 5px;
                            border: 1px solid rgba(0, 168, 151, 0.6);
                            color: #929DA9;
                            font-size: 16px;
    
                            @include tablet{
                                border: 1px solid #818181;
                                padding: 10px 15px;
                            }
                        }   
                    }

                    &--icon{
                        position: relative;
                        width: 100%;

                        &--label{
                            position: absolute;
                            background-color: $green;
                            padding: 10px;
                            height: 100%;
                            color: white;
                            text-align: center;
                            width: 40px;
                        }
                    }

                }

                &__success{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    text-align: center; 
                    position: relative;
                    justify-content: center;
                    align-items: center;


                    h1{
                        font-size: 48px;
                        color: #373737;
                        
                        @include tablet{
                            font-size: 26px;
                            margin-bottom: 18px;
                        }
                    }

                    h3{
                        font-size: 18px;
                        line-height: 21px;
                        color: #00A897;
                        margin-bottom: 25px;
                    }

                    h2{
                        font-size: 22px;
                        font-weight: 500;
                        color: #000000;
                        text-align: center;
                        width: 80%;
                        margin: 0 auto 25px;

                        @include tablet{
                            width: 100%;
                            font-size: 16px;
                            margin-bottom: 18px;
                        }

                        span{
                            color: $green;
                        }
                    }

                    p{
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 30px;

                        @include tablet{
                            font-size: 17px;
                            font-weight: 500;
                        }
                    }

                    ul{
                        li{
                            list-style: none;
                            margin: 10px 0;
                            @include tablet{
                                margin: 15px 0;
                            }
                        }
                    }

                    &__terms{
                        font-size: 16px !important;
                        margin: 0;
                        margin-top: 10px;
                    }

                    &__img--left{
                        position: absolute;
                        bottom: -30px;
                        left: 0;
                        height: 100px;

                        @include tablet{
                            display: none;
                        }
                    }

                    &__img--right{
                        position: absolute;
                        bottom: -30px;
                        right: 0;
                        height: 100px;

                        
                        @include tablet{
                            display: none;
                        }
                    }

                    &__gif--left{
                        position: absolute;
                        bottom: -30px;
                        left: 0;
                        height: 100%;

                        @include tablet{
                            display: none;
                        }
                    }

                    &__gif--right{
                        position: absolute;
                        bottom: -30px;
                        right: 0;
                        height: 100%;
                        
                        @include tablet{
                            display: none;
                        }
                    }

                    &__grey{
                        padding: 12px 17px;
                        background-color: #F0F5F5;

                        p{
                            margin: 0;
                            font-size: 17px;
                            font-weight: 500;

                            b{
                                font-weight: 600;
                            }

                            &:first-child{
                                b{
                                    color: #EC3900;
                                }
                            }

                            &:last-child{
                                margin-top: 4px;
                            }
                        }
                    }

                    button{
                        margin-top: 15px;

                        @include tablet{
                            margin: 0 auto;
                            margin-top: 20px;
                            font-size: 18px;
                            padding: 6px 12px;
                        }
                    }
                }
            }

            button{
                margin: 20px auto 0;
            }

            &__link{
                width: 80%;
                margin: 0 auto;
            }

        }

        &__contact{
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            align-items: center;

            @include tablet{
                flex-direction: column;
                justify-content: center;
            }

            &__img{
                width: 250px;

                @include laptop{
                    width: 30%;
                }

                @include tablet{
                    width: 240px;
                }

                img{
                    width: 100%;
                    height: 100%;
                    // object-fit: contain;
                }
            }

            h2{
                font-size: 40px;
                color: $green;
                font-weight: 500;
                margin-bottom: 25px;
                width: 75%;

                @include laptop{
                    font-size: 28px;
                    margin-bottom: 18px;
                    width: 85%;
                }

                @include tablet{
                    text-align: center;
                    width: 100%;
                }
            }

            &__details{
                display: flex;
                flex-direction: column;
                width: 70%;

                @include tablet{
                    width: 100%;
                }

                h6{
                    font-size: 24px;
                    font-weight: 400;
                    margin-bottom: 10px;

                    @include laptop{
                        font-size: 20px;
                    }

                    @include tablet{
                        width: 100%;
                        font-size: 24px;
                        text-align: center;
                    }
                }

                &__phone{
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    margin-bottom: 5px;
                    color: #000000;

                    @include tablet{
                        justify-content: center;
                    }

                    p,a {
                        font-size: 20px;
                        text-decoration: none;
                        color: #000000;
                        
                        @include laptop{
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }

                    img{
                       height: 20px;
                        object-fit: contain;
                    }
                }
    
                &__mail{
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    color: #000000;
                    margin-bottom: 5px;

                    @include tablet{
                        justify-content: center;
                    }

                    p{
                        font-size: 20px;

                        @include tablet{
                            font-weight: 500;
                        }
                    }
                    img{
                        height: 20px;
                        object-fit: contain;
                    }
                }
            }
            
        }
    }
}