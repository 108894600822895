.login{
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;

    @include tablet{
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        min-height: auto;
    }

    &__home{
        width: 40%;
        background-color: $green;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include tablet{
            width: 100%;
            background: none;
            color: $green;
            margin-bottom: 30px;
        }

        &__img{
            height: 184px;
            object-fit: contain;

            @include laptop{
                height: 160px;
            }

            @include tablet{
                height: 140px;
            }
        }

        &__text{
            display: flex;
            align-items: flex-end;
            margin-top: 30px;

            @include laptop{
                margin-top: 10px;
            }
 
            p{
                font-family: $poppins;
                font-weight: 500;
                font-size: 20px;

                @include laptop{
                    font-size: 14px;
                }

                @include tablet{
                    font-size: 16px;
                }
            }

            img{
                height: 32px;
                object-fit: contain;

                @include laptop{
                    height: 22px;
                }

                @include tablet{
                    height: 30px;
                }
            }
        }

        &__curve{
            img{
                height: 26px;
                object-fit: contain;

                @include laptop{
                    height: 20px;
                }

                @include tablet{
                    height: 24px;
                }
            }
        }
    }

    &__container{
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include tablet{
            width: 100%;
        }

        &__card{
            background-color: $white;
            border: 1px solid $green;
            box-shadow: -6px 6px 0px $green;
            padding: 20px;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40%;
            margin-bottom: 40px;

            @include laptop{
                width: 60%;
                margin-bottom: 30px;
            }

            @include tablet{
                width: 85%;
                padding: 20px 14px;
                border-radius: 20px;
            }

            h3{
                font-family: $poppins;
                font-size: 25px;
                font-weight: 400;
                color: $green;
                margin-bottom: 18px;

                @include tablet{
                    font-size: 25px;
                    margin-bottom: 10px;

                }
            }

            form{
                display: flex;
                flex-direction: column;
                width: 100%;

                div{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 18px;

                    @include tablet{
                        margin-bottom: 15px;
                    }

                    label{
                        font-family: $poppins;
                        font-size: 16px;
                        font-weight: 500;
                        color: $green;
                        margin-bottom: 12px;

                        @include tablet{
                            margin-bottom: 4px;
                        }
                    }

                    input{
                        width: 100%;
                        padding: 12px 18px;
                        background: #EAECEE;
                        border: none;
                        border-radius: 16px;
                        color: $text-grey;
                        font-size: 16px;
                        outline: none;

                        @include tablet{
                            border-radius: 5px;
                        }

                    }
                }

                p{
                    font-size: 18px;
                    margin-bottom: 24px;

                    @include tablet{
                        font-size: 14px;
                    }

                    a{
                        color: $green;
                        font-size: 16px;
                        text-decoration: underline;

                        @include tablet{
                            font-size: 14px;
                        }
                    }
                }

                button{
                    margin: 0 auto;
                }
            }
            
        }
    }
}