.board{
    width: 100%;
    padding: 40px 50px 100px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: 50px;
    height: 100%;
    min-height: calc(100vh - 90px);
    position: relative;

    @include laptop{
        column-gap: 30px;
        padding: 30px 40px;
    }

    @include tablet{
        padding: 20px;
    }

    &__sidebar{
        background: #E2F1F1;
        border: 1px solid $green;
        box-shadow: -4px 4px 0px #01A897;
        border-radius: 20px;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        width: 215px;

        @include laptop{
            width: 20%;
            padding: 15px;
            row-gap: 15px;
        }

        @include tablet{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            border-radius: 0;
            box-shadow: none;
            padding: 20px 14px;
            height: 100vh;
            display: none;
        }

        a{
            text-decoration: none;
            text-align: center;
            border-radius: 10px;
            color: #000000;
            font-size: 18px;
            
            @include laptop{
                padding: 8px 15px;
                font-size: 14px;
            }
        }
        
        &--active{
            padding: 12px 20px;
            border: 1px solid $green;
            background-color: $white;
        }
    }

    &__card{
        background: #FFFFFF;
        border: 1px solid $green;
        box-shadow: -8px 8px 0px $green;
        border-radius: 20px;
        width: calc(100% - 215px - 50px);
        padding: 30px 60px;
        display: flex;

        @include laptop{
            width: 80%;
            padding: 20px 30px;
        }

        @include tablet{
            width: 100%;
            padding: 15px 12px;
        }

        &__form{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 60px;
            width: 100%;

            @include tablet{
                row-gap: 10px;
            }


            &__container{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                width: 80%;
                margin: 0 auto;

                @include laptop{
                    width: 100%;
                }

                @include tablet{
                    width: 100%;
                    flex-direction: column;
                }

                &__field{
                    display: flex;
                    flex-direction: column;
                    width: 45%;
                    margin-bottom: 16px;

                    @include tablet{
                        width: 100%;
                    }
                    
                    label{
                        font-size: 16px;
                        color: $green;
                        margin-bottom: 10px;

                        @include tablet{
                            margin-bottom: 2px;
                        }
                    }
    
                    input{
                        padding: 12px 15px;
                        border-radius: 5px;
                        border: 1px solid rgba(0, 168, 151, 0.6);
                        color: #929DA9;
                        font-size: 16px;

                        @include tablet{
                            padding: 10px 12px;
                            border: 1px solid #818181;
                        }
                    }

                    &__file{
                        input[type="file"]{
                            padding: 6px;
                            border: 2px dotted rgba(0, 168, 151, 0.6);
                            -webkit-appearance: none;
                            text-align: left;
                            -webkit-rtl-ordering:  left;
                            color: $green;
                        }

                        input[type="file"]::-webkit-file-upload-button{
                            -webkit-appearance: none;
                            float: right;
                            margin: 0 0 0 10px;
                            border: 1px solid #aaaaaa;
                            border-radius: 4px;
                            background-color: $green;
                            color: $white;
                            font-size: 14px;
                            padding: 6px 10px;
                         }
                         
                    }

                    select{
                        padding: 12px 15px;
                        border-radius: 5px;
                        border: 1px solid rgba(0, 168, 151, 0.6);
                        color: #929DA9;
                        font-size: 16px;
                    }

                    &__icon{
                        position: relative;
                        width: 100%;
                        overflow: hidden;

                        input{
                            width: 100%;
                            padding-left: 55px;
                        }

                        &--label{
                            position: absolute;
                            background-color: $green;
                            padding: 8px;
                            height: 100%;
                            color: white;
                            text-align: center;
                            width: 50px;
                            border-radius: 5px;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;

                            p{
                                text-align: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 100%;
                            }

                            h6{
                                font-size: 20px;
                                font-weight: 600;
                            }
                        }



                    }
                }
            }

            button{
                padding: 10px 15px;
                font-size: 16px;
                // width: 150px;

                @include laptop{
                    font-size: 16px;
                }

                @include tablet{
                    padding: 12px;
                }
            }
        }
    }

    &__copy{
        display: flex;
        justify-content: space-between;
        // column-gap: 30px;
        width: 80%;
        margin: 0 auto 60px;


        @include laptop{
            width: 100%;
        }

        @include tablet{
            flex-direction: column;
            width: 100%;
            row-gap: 20px;
        }

        &__link{
            border-radius: 5px;
            background: #F0F5F5;
            padding: 5px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;
            width: calc(100% - 150px - 20px);

            @include tablet{
                padding: 6px;
                justify-content: space-between;
                width: 100%;
            }

            button{
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px 7px;
    
                img{
                    height: 21px;
                    object-fit: contain;
                }
            }

        }
        
        p{
            font-size: 14px;
            color: #222A75;
            text-decoration: underline;
            width: 70%;
            word-wrap: break-word;

            @include tablet{
                font-size: 12px;
                width: 60%;
            }
        }

    }
}